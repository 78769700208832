import { FormGroup } from "../../styles/FormGroup";

type PasswordInputProps = {
    name: string;
    label?: string;
    onChange: (name: string, value: string) => void;
    onBlur?: (name: string, value: string) => void;
    value?: string;
    error?: string;
    required?: boolean;
    description?: string;
    [key: string]: any;
};

const PasswordInput = ({
    name,
    label,
    onChange,
    onBlur = null,
    required = false,
    value = "",
    error = "",
    description = "",
    ...props
}: PasswordInputProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        onChange(name, value);
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onBlur) {
            return;
        }

        const { name, value } = e.target;
        onBlur(name, value);
    };

    return (
        <FormGroup $error={!!error} disabled={props.disabled} className={`form-group ${props?.className ?? ""}`}>
            {label ? (
                <label htmlFor={name}>
                    <span> {label} {required ? " *" : ""}</span>
                    <span>{error}</span>
                </label>
            ) : (
                ""
            )}
            <input
                {...props}
                name={name}
                id={name}
                type="password"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
            />
            <div>{description ? <small>{description}</small> : ""}</div>
            {error ? (
                <span className="error">{error}</span>
            ) : (
                ""
            )}
        </FormGroup>
    );
};

export default PasswordInput;
