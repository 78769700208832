import React, { useEffect, useState } from "react";
import Link from "next/link";
import useAuth from "../../hooks/useAuth";
import { HeaderContaier } from "./style";
import ProgressBar from "./ProgressBar";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const HeaderSlim = () => {
    const { user, account } = useAuth();
    const [redirect, setRedirect] = useState("" || null);

    useEffect(() => {
        const redirectLocal = localStorage.getItem("redirect");
        setRedirect(redirectLocal);
    }, [setRedirect]);

    return (
        <HeaderContaier>
            <div className="content">
                <div className="logo-container">
                    <Link href="/">
                        <a>
                            <img src="/images/logo-fotop-laranja.svg" />
                        </a>
                    </Link>
                </div>
                <div className="login">
                    {user ? (
                        <Link href="/marketplace/dashboard">
                            <div>
                                <a>{account.name}</a>
                                <span>{account?.organization.name}</span>
                            </div>
                        </Link>
                    ) : (
                        <Link href={redirect ? `/login?to=${redirect}` : "/login"}>
                            <a>
                                Login
                                <MdOutlineArrowForwardIos size={18} />
                            </a>
                        </Link>
                    )}
                </div>
            </div>
            {/* <ProgressBar /> */}
        </HeaderContaier>
    );
};

export default HeaderSlim;
