import styled from "styled-components";
import theme from "~/styles/theme";

export const HeaderContaier = styled.header`
    background: #ffffff;
    max-height: 3.5rem;
    height: 100%;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    .content {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        align-items: center;
    }

    .logo-container {
        img {
            max-width: 5.5rem;
        }
    }

    .login {
        text-align: right;
        span {
            text-align: right;
            color: ${theme.color.baseLighter};
            font-size: 0.8rem;
        }
        a {
            display: flex;
            align-items: center;
            font-size: 1rem;
            font-weight: 600;
            text-decoration: none;
            color: ${theme.color.terciary.foreground};
            cursor: pointer;
        }

        svg {
            margin-left: 0.5rem;
        }

        @media (max-width: 540px) {
            a {
                max-width: 130px;
                font-size: 0.8rem;
            }
        }
    }

    .bar {
        width: 100%;
        height: 0.5rem;
        position: relative;
        bottom: 0;

        background: #dddddd;
        transform: matrix(1, 0, 0, -1, 0, 0);
    }
`;

export const ProgressBarContainer = styled.div<{ progress: number; totalSteps: number }>`
    width: 100%;
    height: 0.5rem;
    bottom: 0;
    margin-bottom: 1.5rem;

    background: #dddddd;
    transform: matrix(1, 0, 0, -1, 0, 0);

    .bar {
        width: ${({ progress, totalSteps }) => (progress ? (progress / totalSteps) * 100 + "%" : 0)};
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        height: 100%;
        background: ${theme.color.primary.background};
    }
`;
